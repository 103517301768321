/* .arrows is never defined but used in Arrows.js */

* {
  box-sizing: border-box;
}

:root {
  --heights: 100vh;
  --widths: 100%;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.slide-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.slide-title, .slide-description {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  top: 50%;
  position: absolute;
  z-index: 9;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-description {
  top: 70%;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.previous, .next {
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 30px;
  font-weight: bold;
  border-radius: 0 5px 5px 0;
}

.previous:hover, .next:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 200;
}

.dot {
  height: 1rem;
  width: 1rem;
  margin: 0 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.active-dot, .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}