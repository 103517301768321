.about {
  margin-top: 10%;
}

a.about {
  position: relative;
  top:-100px;
  display: block;
  height: 0;
  width:0
}

.about h1 {
  margin-bottom: 1rem;
}

.about p {
  line-height: 1.5rem;
}

.artist-bio {
  margin: 5% 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#artist-info {
  width: 20rem;
  min-width: 250px;
  margin: 0 10px 1rem 0;
  flex: 3
}

#artist-pic {
  width: 350px;
  margin: 10px;
  flex: 1
}

.intention {
  margin: 5% 20%;
}

.intention h3 {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.offerings {
  margin: 0 13% 10%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
}

.offer {
  color: plum;
  margin: 2rem 2rem;
}

.offer h1 {
  margin: 0;
  font-size: 2rem;
}

#signature {
  font: italic small-caps bold 16px/2 cursive;
}