
.NavItems {
  color: white;
  width: 100%;
  height: 70px;
  margin: 0 auto;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
}

.nav-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  padding-bottom: 7px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavItems {
    position: absolute;
  }
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    /* background: rgba(128,128,128, 0.7); */
    background: rgba(0, 0, 0, .5);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 999;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    text-align: center;
    padding: 2rem;
    display: table;
    border-bottom-style: none;
    border-bottom-width: 0;
    /* background: rgba(0, 0, 0, .5); */
    background: rgba(175, 175, 175, .9);
  }

  .nav-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.4rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 1.8rem;
  }

}